@mixin breakpoint($point) {
  @if $point == xs {
    @media #{$media-query--xs} {
      @content;
    }
  }
  @if $point == s {
    @media #{$media-query--s} {
      @content;
    }
  }
  @if $point == m {
    @media #{$media-query--m} {
      @content;
    }
  }
  @if $point == l {
    @media #{$media-query--l} {
      @content;
    }
  }
  @if $point == xl {
    @media #{$media-query--xl} {
      @content;
    }
  }
  @if $point == xs-s {
    @media #{$media-query--xs-s} {
      @content;
    }
  }
  @if $point == xs-s-m {
    @media #{$media-query--xs-s-m} {
      @content;
    }
  }
  @if $point == s-m {
    @media #{$media-query--s-m} {
      @content;
    }
  }
  @if $point == s-m-l {
    @media #{$media-query--s-m-l} {
      @content;
    }
  }
  @if $point == s-m-l-xl {
    @media #{$media-query--s-m-l-xl} {
      @content;
    }
  }
  @if $point == m-l {
    @media #{$media-query--m-l} {
      @content;
    }
  }
  @if $point == m-l-xl {
    @media #{$media-query--m-l-xl} {
      @content;
    }
  }
  @if $point == l-xl {
    @media #{$media-query--l-xl} {
      @content;
    }
  }
}