.hero-text {
  position: relative;
  top: 30px;
  text-align: center;
  @include breakpoint(m) {
    margin-left: 25px;
  }
  @include breakpoint(m-l-xl) {
    text-align: left;
    top: 170px;
  }
  * {
    font-weight: 100;
  }

  .h1 {
    font-size: 35px;
    line-height: 46px;
  }

  .h2 {
    margin: 0;
    position: relative;
    top: 20px;
    color: #E3DBDB;
    font-size: 26px;
    margin-bottom: 13px;
    line-height: 36px;
  }
}

.hero-image {
  position: relative;
  overflow: hidden;
  height: 500px;
  padding: 1em;
  background-color: $color-grey;

  @include breakpoint(m-l-xl) {
    height: 580px;
    padding: 0;

  }

  .images {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    .image{
      margin-top: -22px;
      height: 100%;
      display: none;
      background-size: cover;
      background-position: center center;

      &.first{
        background-image: url("/static/img/slide_1.png");
      }
      &.second{
        background-image: url("/static/img/slide_2.png");
      }
      &.third{
        background-image: url("/static/img/slide_3.png");
      }

      *{
        transition: 0.5s;
      }

      &:first-child {
        display: block;
      }

    }


  }
}