* {
  list-style: none;
  box-sizing: border-box;
}

html {
  height: 100%;
  color: $color-font-black;
}

body {
  height: 100%;
  font-size: $std-fontsize;
  line-height: $std-lineheight;
  background: $color-white;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  transition: transform 500ms ease;

  .nav--is-open & {
    left: 0;
    transform: translate3d(70%, 0, 0);
    transition: transform 500ms ease;
  }
}

h1, .h1 {
  font-size: $font-size-h1;
  color: $color-font-h1;
  font-weight: 300;
}

h3, .h3 {
  font-size: $font-size-h3;
  color: $color-blue-dark;
  font-weight: 300;
  line-height: 37px;
}

h4, .h4 {
  color: #212020;
  font-size: 23px;
}

.max-width {
  max-width: 1080px;
  width: 100%;
  margin: auto;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
}

.page-title-header {
  background-color: #585353;
  padding: 20px 10px;
  margin-bottom: 30px;

  h1 {
    font-size: 27px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 100;
    text-align: center;
  }

  @media (min-width: 500px) {
    h1 {
      text-align: left;
    }
  }
}

.page-description {
  color: #5A5A5A;
  font-size: 15px;
  margin-top: 0;
  max-width: 700px;
}

.text-right {
  text-align: right;
}

.padding-right {
  padding-right: 20px;
}

.small--text-right {
  @include breakpoint(xs-s) {
    text-align: right;
  }
}

.large--text-right {
  @include breakpoint(m-l-xl) {
    text-align: right;
  }
}

.small--text-left {
  @include breakpoint(xs-s) {
    text-align: left;
  }
}

.large--text-left {
  @include breakpoint(m-l-xl) {
    text-align: left;
  }
}

.small--text-center {
  @include breakpoint(xs-s) {
    text-align: center;
  }
}

.large--text-center {
  @include breakpoint(m-l-xl) {
    text-align: center;
  }
}

.small--no-padding {
  @include breakpoint(xs-s) {
    padding: 0 !important;
  }
}

.large--no-padding {
  @include breakpoint(m-l-xl) {
    padding: 0 !important;
  }
}

.large--hide {
  @include breakpoint(m-l-xl) {
    display: none;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.main {
  padding: $std-padding 10px;
  min-height: $std-height-footer * 2;
  margin-bottom: -$std-height-footer;
  &:after {
    height: $std-height-footer;
    content: "";
    display: block;
    //box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.75);
  }
  @include breakpoint(m-l-xl) {
    padding: $std-padding 10px;
    //min-height: 400px;
  }
}

.seperated-group {
  margin-top: 44px;
  border-top: 1px solid #EBEBEB;
  padding-top: 31px;
}

.pdf-download {
  margin-bottom: 20px;

  i {
    font-size: 30px;
    color: $color-blue-dark;
    margin-right: 10px;
    margin-top: 9px;
    float: left;
  }

  a {
    font-size: 16px;
    color: $color-blue-dark;
  }
}