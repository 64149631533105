$color-black: #000;
$color-blue-dark: #1F4A79;
$color-blue-dark-transparency: rgba(31,74,121,0.8);
$color-grey: #383333;
$color-white: #fff;

$color-font-white: $color-white;
$color-font-grey: #AEACAC;
$color-font-black: $color-black;

// Fonts
$font-size-h1: 25px;
$color-font-h1: #4a4a4a;

$font-size-h2: 34px;
$color-font-h1: $color-font-white;

$font-size-h3: 30px;
$color-font-h3: $color-font-h1;

$font-size-h4: 25px;
$color-font-h4: #E3DBDB;

$font-size-h5: 25px;
$color-font-h5: #1d3f65;

$font-size-h6: 24px;
$color-font-h6: $color-font-h1;




// TODO: change property.
$std-margin: 10px;
$std-lineheight: 25px;
$std-padding: 25px;
$std-fontsize: 16px;
$std-height-footer: 75px;



// Grid
$media-query--xs: '(max-width: 479px)';
$media-query--s: '(min-width: 480px) and (max-width: 767px)';
$media-query--m: '(min-width: 768px) and (max-width: 1023px)';
$media-query--l: '(min-width: 1024px) and (max-width: 1279px)';
$media-query--xl: '(min-width: 1280px)';

$media-query--xs-s: '(max-width: 767px)';
$media-query--xs-s-m: '(max-width: 1023px)';
$media-query--s-m: '(min-width: 480px) and (max-width: 1023px)';
$media-query--s-m-l: '(min-width: 480px) and (max-width: 1279px)';
$media-query--s-m-l-xl: '(min-width: 480px)';
$media-query--m-l: '(min-width: 768px) and (max-width: 1279px)';
$media-query--m-l-xl: '(min-width: 768px)';
$media-query--l-xl: '(min-width: 1024px)';