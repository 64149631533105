.infobar {
  background: $color-black;
  min-height: 30px;
  height: auto;
  color: $color-font-white;
  line-height: 30px;

  .small--text-center {
    padding-left: 0;
  }
  a {
    color: #ffb69e;
    &:visited, &:focus {
      color: #ffb69e;
    }
  }
  @include breakpoint(m) {
    padding: 0 25px;
  }
}