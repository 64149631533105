.demo-block {
  background-color: $color-blue-dark;
  color: $color-font-white;
  padding: 18px;
}

p {
  margin-bottom: 18px;
}

body.demo {
  max-width: 1200px;
  margin: 18px auto;
  padding: 0 18px;
}

.grid-block {
  background: #ccc;
  &:nth-child(odd) {
    background: #d3fbd0;
  }
}