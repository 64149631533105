.approach {


  #map {
    height: 500px;
    margin-bottom: 50px;
  }
  p {
    font-size: 14px;
    color: #4a4a4a;
    margin-bottom: 0;
    margin-top: 0;
  }
  .marker {
    width: 80%;
    height: auto;
  }
}


@media (min-width: 767px) {
  .address {
    float: right;
    
    a{
      color: $color-blue-dark;
    }

    p {
      font-size: 14px;
      color: #4a4a4a;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
