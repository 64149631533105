.partner-module {
  position: relative;
  top: -10px;
  background: $color-grey;
  @include breakpoint(m) {
    padding: 0 10px;
  }
  &__partner-logo {
    margin: 15px 0;
    @include breakpoint(m-l-xl) {
      width: 100%;
      max-width: 200px;
    }

  }

}

.roland {
  max-width: 170px;
}

.partner-intro{
  margin-bottom: 50px;

  img{
    max-width: 100%;
    border-radius: 5px;
  }
}

.partner-list {
  list-style: none;
  margin: 0;
  padding: 0;

  h3 {
    padding-left: 26px;
  }

  > li {
    margin-bottom: 43px;
    padding: 18px 0;
    background: #f1eeee;

  }

  .cv {
    li {
      padding: 8px 0 8px 22px;
      border-left: 1px solid #d6d6d6;

      .year {
        color: #1f4a79;
        display: inline-block;
        width: 90px;
        vertical-align: top;

        @media (max-width: 479px){
          display: block;
        }
      }

      .long-text {
        display: inline-block;
        width: 80%;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
      &:before {
        content: " ";
        display: inline-block;
        background: #1f4a79;
        width: 20px;
        margin-left: -33px;
        position: absolute;
        border-radius: 50px;
        vertical-align: middle;
        height: 20px;
      }
    }
  }

  figure {
    margin: 0;
    margin-left: 15px;
    overflow: hidden;
    height: 400px;
    position: relative;
    border-radius: 5px;

    figcaption {
      position: absolute;
      background: rgba(31, 74, 121, 0.83);
      left: 0;
      color: #fff;
      text-align: center;
      bottom: 0;
      right: 0;
      padding: 11px;
    }

    img {
      display: block;
      max-width: 100%;
    }

  }

}