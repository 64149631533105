.reservation {
  &__button {
    position: absolute;
    bottom: 0;
    padding: 15px;
    border-radius: 5px 5px 0 0;
    background: $color-blue-dark;
    color: $color-font-white;
    &:hover {
      transition: 0.5s;
      background-color: darken($color-blue-dark, 5);
    }
    .fa {
      margin-right: 5px;
    }
    @include breakpoint(m) {
      margin-left: 25px;
    }
  }
}