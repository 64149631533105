.skills {
  
  a{
    color: currentColor;
  }

  .skill-col {
    border-right: 1px solid #E1E1E1;
    padding-left: 15px;

    &:last-child {
      border-right: none;
    }
  }

  .skill {
    margin-bottom: 14px;
    cursor: pointer;

    a:hover {
      .skill-icon {
        background-color: $color-blue-dark;
        color: #fff;
      }

      .skill-title {
        color: $color-blue-dark;
        transition: 0.5s;
      }
    }

    .skill-icon {
      display: inline-block;
      border: 2px solid $color-blue-dark;
      border-radius: 50%;
      text-align: center;
      color: $color-blue-dark;
      width: 46px;
      height: 46px;
      padding-top: 9px;
      font-size: 22px;
      transition: 0.5s;
    }

    .skill-title {
      font-size: 18px;
      margin-left: 7px;
      color: #585353;
    }
  }
  &.skill-only {
    .skill-col {
      padding-right: 20px;
      @include breakpoint(xs-s) {
        border-right: 0;
      }
      @include breakpoint(m-l-xl) {
        min-height: 400px;
      }
    }
    ul {
      padding-left: 0;
    }
    .skill__contact-person {
      position: relative;
      &--img {
        width: 50px;
        height: 50px;
        border-radius: 100px;
      }
      &--name {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 20px;
      }
    }
  }
}

.page-title-header--skill {
  background-image: url('../img/hero_large.png');
  background-repeat:  no-repeat;
  background-size: cover;
  position: relative;
  height: 400px;
  background-position: center;

  &.business{
    background-image: url("../img/skills/gewerblicher_rechtsschutz.jpg");
  }
  &.agb{
    background-image: url("../img/skills/agb.jpg");
  }
  &.inherit{
    background-image: url("../img/skills/erbrecht.jpg");
  }
  &.family{
    background-image: url("../img/skills/familienrecht.jpg");
  }

  &.community{
    background-image: url("../img/skills/gesellschaftsrecht.jpg");
  }

  &.house{
    background-image: url("../img/skills/grundstuecksrecht.jpg");

  }
  &.it{
    background-image: url("../img/skills/it.jpg");
  }
  &.work{
    background-position: 0 54%;
    background-image: url("../img/skills/arbeits.jpg");
  }
  &.it{
    background-image: url("../img/skills/it.jpg");
  }
  &.medicine{
    background-image: url("../img/skills/medizin.jpg");
  }
  &.rent{
    background-image: url("../img/skills/mietrecht.jpg");
  }
  &.notar{
    background-image: url("../img/skills/notariat.jpg");
  }
  &.build{
    background-image: url("../img/skills/bau.jpg");
  }
  &.travel{
    background-image: url("../img/skills/reise.jpg");
  }
  &.traffic{
    background-image: url("../img/skills/verkehrsrecht.jpg");
  }
  &.agreement{
    background-image: url("../img/skills/vertrag.jpg");
  }
  &.manage{
    background-position: 0 28%;
    background-image: url("../img/skills/verwaltung.jpg");
  }
  &.docs{
    background-image: url("../img/vollmachten.png");
    background-position: 0 32%;

  }
  &.flat{
    background-image: url("../img/skills/w_eigentum.jpg");
    background-position: 0 18%;

  }

  h1 {
    position: absolute;
    bottom: 0;
    margin: 0;
    font-size: 17px;
    text-transform: initial;
    line-height: 31px;
    text-align: center;
    display: block;
    background: rgba(31, 74, 121, 0.83);
    padding: 17px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
}