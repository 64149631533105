.open-panel, .close-panel {
  display: inline-block;
  position: absolute;
  color: $color-font-white;
  font-size: 25px;
  top: 15px;
  left: 10px;
  @include breakpoint(m-l-xl) {
    display: none;
  }
  &:visited, &:focus {
    color: $color-font-white;
  }
}

.nav {
  width: 71%;
  position: absolute;
  left: -71%;
  top: -1px;
  height: 101%;
  background: $color-blue-dark;
  font-size: 15px;

  @include breakpoint(m) {
    position: relative;
    top: 27px;
    left: 190px;
  }

  @include breakpoint(l-xl) {
    width: auto;
    position: relative;
    left: 0;
    background: none;
    top: 53px;
  }

  .nav__list {
    border-top: 1px solid #888;
    padding: 50px 0 0 0;
    @include breakpoint(m-l-xl) {
      padding: 0;
      width: auto;
      float: right;
      border-top: none;
    }
    .nav__item {
      float: none;
      width: 100%;
      text-align: left;
      padding: 20px 1em;
      box-sizing: border-box;
      border-bottom: 1px solid $color-white;
      @include breakpoint(m-l-xl) {
        display: block;
        list-style-type: none;
        float: left;
        padding: 0;
        margin: 0 15px 0 0;
        width: auto;
        text-align: center;
        border-bottom: 0;

        &:after {
          content: '|';
          position: absolute;
          width: 15px;
          height: 100%;
          color: $color-font-white;
        }

        &--startpage {
          background-color: #86A9BD;
          border-right: 7px solid #fff;
          border-left: 7px solid #fff;
          margin: -3px 10px;
          padding: 4px 11px;

          &:after {
            content: '';
          }
        }

        &--is-last {
          margin-right: 0;

          &:after{
            content: "";
          }
        }
      }
      .nav__link {
        color: $color-font-white;
        line-height: 28px;

        &:focus, &:visited {
          color: $color-font-white;
        }
        &:hover {
          color: darken($color-font-white, 15);
        }
        &--is-active {
          &:after {
            font-family: 'FontAwesome';
            content: '\f0d9';
            position: absolute;
            display: inline-block;
            right: 10px;
            @include breakpoint(m-l-xl) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.header {
  min-height: 95px;
  background: #FFFFFF;
}

.header__divider {
  height: 35px;
  position: absolute;
  width: 100%;
  top: 97px;
  background: $color-blue-dark;
  @include breakpoint(m-l-xl) {
    top: 80px;

  }
}

.header__logo {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 10px;
  @include breakpoint(m) {
    img {
      width: 80%;
      left: -220px;
      position: relative;
      top: -10px;
    }
  }
  @include breakpoint(l-xl) {
    position: relative;
    top: 3px;
  }
}
