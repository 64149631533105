.btn {
  position: relative;
  top: 20px;
  padding: 15px;
  color: $color-font-white;
  background: $color-grey;
  border-radius: 5px;

  &:hover{
    transition: 0.5s;
    background-color: darken($color-grey, 5);
  }
}