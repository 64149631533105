.footer {
  height: $std-height-footer;
  margin-bottom: 10px;
  * {
    color: $color-font-grey;
  }
  &__link {
    &:hover {
      color: $color-blue-dark;
    }
    &:visited, &:focus {
      color: $color-font-grey;
    }
  }
}