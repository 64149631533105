#history-timeline {
  letter-spacing: normal;

  h1, h2, h3, h4, p, div {
    font-family: OpenSans !important;
  }
  .vco-toolbar {
    display: none;
  }

  .vco-timeline .vco-navigation .timenav-background {
    background-color: $color-blue-dark;
  }

  .vco-timeline .vco-navigation .timenav-background .timenav-tag-row-1 {
    background-color: rgba(0, 0, 0, 0.22);
  }

  .vco-timeline .vco-navigation .timenav-background .timenav-tag div h3 {
    text-shadow: none;
    color: #fff;
  }

  .vco-storyjs h2.start {
    color: $color-blue-dark;
    margin-bottom: 10px;

    & + p {
      display: none;
    }
  }

  .vco-timeline .vco-navigation .timenav-background .timenav-line {
    background-color: #fff;
  }

  .vco-timeline .vco-navigation .timenav .content .marker.active .dot {
    background-color: $color-blue-dark;
  }

  .vco-timeline .vco-navigation .timenav .content .marker.active .flag .flag-content h3 {
    color: $color-blue-dark;
  }
  .vco-timeline .vco-navigation .timenav .content .marker .flag .flag-content .thumbnail img {
    border-radius: 50%;
    border: none;
  }

}