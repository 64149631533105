.input {
  display: block;
  border-radius: 3px;
  border: 1px solid $color-font-grey;
  width: 100%;
  height: 40px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: $color-blue-dark-transparency;
  }
}

.label {
  font-size: 13px;
}

.textarea {
  @extend .input;
  min-height: 250px;
  padding: 10px;
}

.message {
  margin-top: 30px;
}

.submit {
  @extend .input;
  margin-top: 20px;
  height: 50px;
  color: $color-font-white;
  background: $color-blue-dark;
  //padding: 10px;
  border: none;
  cursor: pointer;

  &:hover{
    transition: 0.5s;
    background: darken($color-blue-dark, 5);

  }
}