.group {
  @include breakpoint(m) {
    padding: 0 10px;
  }
  &__picture {
    width: 100%;
    margin-top: 45px;
  }
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  background: rgba(50, 50, 50, .6);
  left: 0;
}

.alert {
  padding: 15px 20px;
  background: $color-blue-dark;
  color: #fff;
  border-radius: 6px;
  margin-bottom: 24px;


  &.error {
    background: #bb5555;
  }
}