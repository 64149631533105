@import "partials/variables";
@import "partials/mixins";

/********************/
/*      Grid        */
/********************/
@import "partials/grid";
@import "partials/modules/grid-example"; // Should be deleted at the end.

/********************/
/*      Global      */
/********************/
@import "partials/global";
@import "partials/navigation";
@import "partials/footer";

/********************/
/*      Modules     */
/********************/
@import "partials/modules/button";
@import "partials/modules/infobar";
@import "partials/modules/category";
@import "partials/modules/skills";
@import "partials/modules/timeline";
@import "partials/modules/scaffolding";
@import "partials/modules/reservation";
@import "partials/modules/partners";
@import "partials/modules/hero";
@import "partials/modules/forms";
@import "partials/pages/approach";
@import "partials/pages/powers";
