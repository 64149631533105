.category {
  text-align: center;
  position: relative;

  &:hover {
    transition: 0.5s;
    opacity: 0.8;
  }

  &__title {
    color: $color-font-white;
    position: absolute;
    padding: 5px 15px;
    background: $color-blue-dark-transparency;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    top: 10px;
  }
  &__sub-title {
    color: $color-font-white;
    position: absolute;
    bottom: 15px;
    margin-left: 15px;
  }
}